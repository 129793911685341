import React from 'react'
import './Security.scss'

export const Security = () => {
  return (
    <div className='wrapper'>SECURITY BLOCK</div>
  )
}

export default Security
