import React from 'react'
import './Settings.scss'

export const Settings = () => {
  return (
    <div className='wrapper'>SETTINGS BLOCK</div>
  )
}

export default Settings
